export const discoverData = [
    {
        id: 1,
        img: require('../../images/conversion.svg').default,
        title: "Highest Conversion",
        description: "Coupled with the fastest tome to market and growth across Africa"
    },
    {
        id: 2,
        img: require('../../images/real-time.svg').default,
        title: "Real-Time View",
        description: "For your business, revenue, acquisition and customer feedback"
    },
    {
        id: 3,
        img: require('../../images/subscription.svg').default,
        title: "Largest Subscription & One-Time payment",
        description: "Across our footprint in the region"
    },
    {
        id: 4,
        img: require('../../images/onboarding.svg').default,
        title: "Fastest On-boarding",
        description: "With a simple business model"
    },
    {
        id: 5,
        img: require('../../images/mobile-world.svg').default,
        title: "We are your bond between your digital offering & mobile world",
        description: "Significant end-user uptake. Most advanced billing Technology"
    },
    {
        id: 6,
        img: require('../../images/api.svg').default,
        title: "One API",
        description: "Connects merchants with millions of potential users through MNOs in 24 countries"
    },
    {
        id: 7,
        img: require('../../images/secure.svg').default,
        title: "Secure & Convenient",
        description: "Optimum click purchase experience"
    },
    {
        id: 8,
        img: require('../../images/operations.svg').default,
        title: "Handling all Operators' Communication &",
        description: "Services approval on our partners' behalf"
    },
    {
        id: 9,
        img: require('../../images/population.svg').default,
        title: "Accessible Population",
        description: "Reach the un-banked! Mobile phone owners"
    },
    {
        id: 10,
        img: require('../../images/presence.svg').default,
        title: "Our Local Presence",
        description: "Facilitates the finance, legal and operation processes"
    },
    {
        id: 12,
        img: require('../../images/testing.svg').default,
        title: "Testing our Services &",
        description: "Certifying all for free across all operators"
    }
]
