import styled from 'styled-components'
import {Link} from "react-router-dom"

export const FooterContainer = styled.div`
  background-color: #101522;
  width: 100%;
`

export const FooterWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`

export const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  grid-gap: 1rem;
  
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const FooterLinkItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
    height: 250px;
    max-height: 250px;
    overflow: hidden;
  }
`

export const FooterLinkTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const FooterLink = styled(Link)`
  color: #dcdcdc;
  text-decoration: none;
  margin-bottom: 1rem;
  font-size: 15px;

  &:hover {
    color: #F69426;
    transition: .3s ease-out;
  }
`

export const SocialMedia = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: 300px;
  align-items: center;

  svg {
    font-size: 2.1rem;
  }
`

export const SocialMediaIcon = styled.a`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
`
