import React from 'react'
import {PartnersContainer, PartnersHeader, PartnersImage} from './partners-styles'
import PartnersImg from '../../images/partners.svg';

function Partners() {
    return (
        <PartnersContainer>
            <PartnersHeader>Our Partners</PartnersHeader>
            <PartnersImage src={PartnersImg}/>
        </PartnersContainer>
    )
}

export default Partners
