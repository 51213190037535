export const numbersData = [
    {
        id: 1,
        number: 24,
        title: "Countries",
        label: "",
        img: require('../../images/countries.svg').default,
    },
    {
        id: 2,
        number: 20,
        title: "Merchants",
        label: "",
        img: require('../../images/merchants.svg').default,
    },
    {
        id: 3,
        number: 600,
        label: "M",
        title: "Total Addressable Market",
        img: require('../../images/market.svg').default,
    },
    {
        id: 4,
        number: 0,
        label: "B",
        title: "Successful Transactions",
        img: require('../../images/transactions.svg').default,
    },
    {
        id: 5,
        number: 54,
        label: "",
        title: "Live Operators/Wallet Connections",
        img: require('../../images/live.svg').default,
    },
    {
        id: 6,
        number: 10,
        label: "M",
        title: "Transactions/Day",
        img: require('../../images/transactions.svg').default,
    },
    {
        id: 7,
        number: 100,
        label: "",
        title: "Services",
        img: require('../../images/services2.svg').default,
    },
    {
        id: 8,
        number: 15,
        label: "M",
        title: "Live Users",
        img: require('../../images/users.svg').default,
    }
]
