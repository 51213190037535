import React from 'react'
import {
    ImageWrap,
    Img,
    SectionBody,
    SectionContent,
    SectionImageArea,
    SectionImageAreaImage,
    SectionImageAreaInfo,
    SectionInfo,
    SectionInfoPoint,
    SectionSubHeader,
    SectionTitle,
    WhoAreWeContainer,
    Wrapper
} from "./who-are-we-styles"
import {Information} from "./WhoAreWeData"

function WhoAreWe() {
    return (
        <>
            <WhoAreWeContainer id={'about'}>
                <Wrapper>
                    <SectionTitle>Who Are We?</SectionTitle>
                    <SectionBody>
                        <ImageWrap>
                            <Img src={require('../../images/about.svg').default} alt={'who are we'}/>
                        </ImageWrap>
                        <SectionContent>
                            <SectionSubHeader>Africa Development Connect Limited (ADC)</SectionSubHeader>
                            <SectionInfo>
                                <SectionInfoPoint>{Information.pointOne}</SectionInfoPoint>
                                <SectionInfoPoint>{Information.pointTwo}</SectionInfoPoint>
                                <SectionInfoPoint>{Information.pointThree}</SectionInfoPoint>
                                <SectionInfoPoint>{Information.pointFour}</SectionInfoPoint>
                            </SectionInfo>
                        </SectionContent>
                    </SectionBody>
                    <SectionImageArea>
                        <SectionImageAreaInfo>{Information.additionalInfo}</SectionImageAreaInfo>
                        <SectionImageAreaImage src={Information.additionalImage} alt={'additional information'}/>
                    </SectionImageArea>
                </Wrapper>
            </WhoAreWeContainer>
        </>
    )
}

export default WhoAreWe
