import styled from "styled-components"

export const NumbersContainer = styled.div`
  margin: 1rem auto;
`

export const NumbersWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  margin: auto;
  padding: 1rem;
`

export const NumbersHeading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 50px;
  margin-top: 1rem;
`

export const NumbersContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  width: 100%;
  padding: 1rem;
  
  @media screen and (max-width: 1028px){
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media screen and (max-width: 768px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 568px){
    grid-template-columns: 1fr;
    grid-gap: .5rem;
  }
`

export const NumbersContentBody = styled.div`
  display: grid;
  grid-template-columns: .4fr 1fr;
  grid-gap: .5rem;
  align-items: center;
  min-height: 10rem;

  @media screen and (max-width: 568px){
    grid-template-columns: .3fr 1.2fr;
  }
`

export const NumbersIcon = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;

  @media screen and (max-width: 568px){
    width: 70px;
    height: 70px;
  }
`

export const NumbersInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const NumbersCount = styled.h2`
    color: #232323;
  font-size: 1.8rem;
`

export const NumbersDescription = styled.p`
    margin-top: 1rem;
`
