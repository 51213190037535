export const homeObj1 = {
    id: 'products',
    lightBg: false, //
    lightText: true, //
    lightTextDesc: true,
    topLine: 'Payment Gateway',
    headLine: 'ADC Payments Gateway',
    description: 'This product enables real-time merchant payments collection and settlement via an app of USSD channel by integrating financial institutions, card systems and mobile money wallets to the different value chains like Ecommerce, Insurance, Utility bills and Loan facilities',
    btnLabel: 'Visit Site',
    imgStart: false,
    img: require('../../images/products.svg').default,
    alt: 'products',
    dark: true,
    primary: true,
    darkText: false //
}

export const homeObj2 = {
    id: 'products',
    lightBg: false, //
    lightText: true, //
    lightTextDesc: true,
    topLine: 'Bulk SMS',
    headLine: 'ADC Bulk SMS Portal',
    description: 'Labeled SMS. For this case, SMSs are sent using the name of the organisations or any other name the SMS ought to show. Personalized messages. This is when an SMS can be sent with the name of the customers by uploading an excel file i.e. Dear John ............... This is to avoid the tedious work of assigning different numbers.\n',
    btnLabel: 'Visit Site',
    imgStart: true,
    img: require('../../images/sms.svg').default,
    alt: 'sms',
    dark: true,
    primary: true,
    darkText: false //
}

export const homeObj3 = {
    id: 'products',
    lightBg: true, //
    lightText: false, //
    lightTextDesc: true,
    topLine: 'Wallet',
    headLine: 'The ADC Wallet',
    description: 'This is the product provided to banks or MNOs to facilitate wallet -to-wallet transfers and mobile banking services. The service is available through different channels such as USSD, SMS, Web, Mobile Applications and IVR.',
    btnLabel: 'Visit Site',
    imgStart: false,
    img: require('../../images/wallet.svg').default,
    alt: 'wallet',
    dark: true,
    primary: true,
    darkText: true //
}

export const homeObj4 = {
    id: 'products',
    lightBg: true, //
    lightText: false, //
    lightTextDesc: true,
    topLine: 'Builds',
    headLine: 'ADC Builds',
    description: "In this service, we solution and develop according to client's needs. This can be a platform, a website, checkout or applications etc.",
    imgStart: true,
    img: require('../../images/builds.svg').default,
    alt: 'wallet',
    dark: true,
    primary: true,
    darkText: true //
}

export const homeObj5 = {
    id: 'products',
    lightBg: true, //
    lightText: false, //
    lightTextDesc: true,
    topLine: 'Billing',
    headLine: 'The ADC Checkout/Direct Carrier Billing',
    description: 'This product connects clients existing platforms to payment channels, be it card, mobile money, bank or a carrier billing platform. Direct carrier billing is easy for customers to use thus it does not only drive airtime revenues for MNOs but it also drives Data revenues.',
    btnLabel: 'Visit Site',
    imgStart: false,
    img: require('../../images/billing.svg').default,
    alt: 'billing',
    dark: true,
    primary: true,
    darkText: true //
}

export const homeObj6 = {
    id: 'products',
    lightBg: false, //
    lightText: true, //
    lightTextDesc: true,
    topLine: 'Airtime',
    headLine: 'ADC Airtime Portal',
    description: 'This is a portal used to sale retail airtime as opposed to scratch cards and for corporate customers to disburse bulk airtime to employees on one click',
    btnLabel: 'Visit Site',
    imgStart: true,
    img: require('../../images/card.svg').default,
    alt: 'airtime',
    dark: true,
    primary: true,
    darkText: false //
}

export const homeObj7 = {
    id: 'products',
    lightBg: false, //
    lightText: true, //
    lightTextDesc: true,
    topLine: 'Ussd',
    headLine: 'ADC USSD Gateway',
    description: 'This refers to USSD aggregation and menu development for different services',
    btnLabel: 'Visit Site',
    imgStart: false,
    img: require('../../images/ussd.svg').default,
    alt: 'ussd',
    dark: true,
    primary: true,
    darkText: false //
}
