import styled from "styled-components"

export const WhoAreWeContainer = styled.div`
  margin: auto;
  background: #ffffff;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  padding: 1rem;
  flex-direction: column;
`

export const SectionTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 50px;
  margin-top: 1rem;
`

export const SectionBody = styled.div`
  display: grid;
  grid-template-columns: .5fr 2fr;
  padding: 0 2rem;

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
  }
`

export const ImageWrap = styled.div`
  width: 100%;
  max-height: 200px;
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  height: 100%;
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionSubHeader = styled.h2`
  font-weight: 600;
`

export const SectionInfo = styled.ul`
  margin: 1rem 0;
`

export const SectionInfoPoint = styled.li`
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
`

export const SectionImageArea = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.3fr;
  align-items: center;
  grid-gap: 2rem;
  padding: 0 2rem;
  
  @media screen and (max-width: 1078px){
    grid-template-columns: 1.8fr 1.5fr;
  }
  
  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
  }
`

export const SectionImageAreaInfo = styled.p`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
`

export const SectionImageAreaImage = styled.img`
  border-radius: .8rem;
  width: 100%;
  object-fit: cover;
  border: 1px solid #dcdcdc90;
`
