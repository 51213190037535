import React from 'react'
import {
    ServicesCard, ServicesContainer, ServicesH1, ServicesH2, ServicesIcon, ServicesP, ServicesWrapper
} from './service-styles'
import Icon1 from '../../images/web_mobile.svg'
import Icon2 from '../../images/support.svg'
import Icon3 from '../../images/cloud.svg'
import Icon4 from '../../images/development.svg'

function Services() {
    return (
        <ServicesContainer id={'services'}>
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Web and Mobile Applications</ServicesH2>
                    <ServicesP>We help reduce your fees and increase your overall revenue</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4}/>
                    <ServicesH2>Development & Operations</ServicesH2>
                    <ServicesP>Driving development and operations excellence through modern Agile methodologies</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>Integrations</ServicesH2>
                    <ServicesP>Cloud native enterprise integration solutions</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Apps Support</ServicesH2>
                    <ServicesP>24/7 Support</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
