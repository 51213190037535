import React from 'react'
import {
    NumbersContainer,
    NumbersContent,
    NumbersContentBody,
    NumbersCount,
    NumbersDescription,
    NumbersHeading,
    NumbersIcon,
    NumbersInfo,
    NumbersWrapper
} from "./numbers-styles"
import {numbersData} from "./NumbersData"
import CountUp from "react-countup"

function Numbers() {
    return (
        <NumbersContainer>
            <NumbersWrapper>
                <NumbersHeading>Our Numbers</NumbersHeading>
                <NumbersContent>
                    {numbersData.map(data => (
                        <NumbersContentBody key={data.id}>
                            <NumbersIcon src={data.img}/>
                            <NumbersInfo>
                                <NumbersCount>
                                    {data.number !== 0 && (
                                        <CountUp start={0} end={data.number} duration={5}/>
                                    )}
                                    {data.label !== '' && data.label}+
                                </NumbersCount>
                                <NumbersDescription>{data.title}</NumbersDescription>
                            </NumbersInfo>
                        </NumbersContentBody>
                    ))}
                </NumbersContent>
            </NumbersWrapper>
        </NumbersContainer>
    )
}

export default Numbers
