import React from 'react'
import {CloseIcon, Icon, SidebarContainer, SidebarLink, SidebarMenu, SidebarWrapper} from './sidebar-styles'

function Sidebar({isOpen, toggle}) {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to={'hero'} onClick={toggle}>Home</SidebarLink>
                    <SidebarLink to={'about'} onClick={toggle}>About</SidebarLink>
                    <SidebarLink to={'products'} onClick={toggle}>Products</SidebarLink>
                    <SidebarLink to={'discover'} onClick={toggle}>Discover</SidebarLink>
                    <SidebarLink to={'contact'} onClick={toggle}>Contact Us</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
