import React, {useState} from 'react'
import {
    ArrowForward,
    ArrowRight,
    HeroBg,
    HeroBtnWrapper,
    HeroContainer,
    HeroContent,
    HeroH1,
    HeroP,
    VideoBg
} from './hero-style'
import {Button} from "../ButtonElement"
import Video from '../../videos/video.mp4'

function HeroSection() {
    const [hover, setHover] = useState(false)

    const onHover = () => setHover(!hover)
    return (
        <HeroContainer id={'hero'}>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type={'video/mp4'}/>
            </HeroBg>
            <HeroContent>
                <HeroH1>Content and Payment Aggregation Made Easy</HeroH1>
                <HeroP>
                    Contact us today for more information.
                </HeroP>
                <HeroBtnWrapper>
                    <Button to={'contact'} onMouseEnter={onHover} onMouseLeave={onHover} primary={'true'} dark={'true'}
                            big={'true'} smooth={true} duration={500} spy={true} exact={'true'}>
                        Get In Touch {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
