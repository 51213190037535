import styled from 'styled-components'

export const PartnersContainer = styled.div`
  height: 600px;
  max-width: 1400px;
  display: flex;
  margin: auto;
  padding: 5rem 2rem 0 2rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const PartnersHeader = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 50px;
`

export const PartnersImage = styled.img`
    width: 100%;
`
