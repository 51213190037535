import styled from 'styled-components'

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#dcdcdc80')};
  padding: 10px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  min-height: 300px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
`

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: ${({imgStart}) => (imgStart ? '1fr 2fr' : '2fr 1fr')};
  grid-gap: 1rem;
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    align-items: flex-start;
  }
`

export const ColumnOne = styled.div`
  width: 100%;
  grid-area: col1;
  height: min-content;
`

export const ColumnTwo = styled.div`
  width: 100%;
  grid-area: col2;
  height: min-content;
`

export const TextWrapper = styled.div`
  margin: 0 1rem;
  padding: 1rem;
`

export const TopLine = styled.p`
  color: #F69426;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 10px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 25px;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#929292' : '#010606')};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`

export const SubHeading = styled.p`
  margin-bottom: 1rem;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  color: ${({darkText}) => (darkText ? '#010606' : '#212121')};
`

export const ImgWrap = styled.div`
  height: 250px;

  @media screen and (max-width: 878px) {
    height: 150px;
  }
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  height: 100%;
`
