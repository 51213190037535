import React from 'react'
import {
    ColumnOne,
    ColumnTwo,
    Heading,
    Img,
    ImgWrap,
    InfoContainer,
    InfoRow,
    InfoWrapper,
    SubHeading,
    TextWrapper,
    TopLine
} from './info-style'

function InfoSection({
                         lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, img, alt
                     }) {
    return (
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <ColumnOne>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headLine}</Heading>
                            <SubHeading darkText={darkText}>{description}</SubHeading>
                        </TextWrapper>
                    </ColumnOne>
                    <ColumnTwo>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </ColumnTwo>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    )
}

export default InfoSection
