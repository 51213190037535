import styled from 'styled-components'

export const ContactContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 2rem;
  height: 600px;
`

export const ContactContent = styled.div`
  padding: 2rem;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  display: flex;
  margin: 1rem;
  flex-direction: column;
`

export const ContactHeader = styled.h2`
  font-weight: 650;
`

export const ContactInput = styled.input`
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background: aliceblue;

  &:focus {
    outline: none;
  }
`

export const ContactComment = styled.textarea`
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background: aliceblue;
  height: 10rem;
  max-height: 10rem;
  min-height: 10rem;
  min-width: 100%;
  width: 100%;
  max-width: 100%;

  &:focus {
    outline: none;
  }
`

export const CommentButton = styled.button`
  padding: .7rem 1rem;
  max-width: 200px;
  cursor: pointer;
  outline: none;
  background: #F69426;
  border: none;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;

  @media screen and (max-width: 568px) {
    max-width: 100%;
  }
`
