import React, {useEffect, useState} from 'react'
import {Logo, MobileIcon, Nav, NavContainer, NavItem, NavLinks, NavLogo, NavMenu} from './nav-style'
import {FaBars} from 'react-icons/fa'
import {IconContext} from "react-icons/lib"
import logo from './../../logo_green.svg'
import {animateScroll as scroll} from 'react-scroll'

function Navbar({toggle}) {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 85) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <>
            <IconContext.Provider value={{color: '#fff'}}>
                <Nav scrollNav={scrollNav}>
                    <NavContainer>
                        <NavLogo to={'/'} onClick={toggleHome}>
                            <Logo src={logo}/>
                        </NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars/>
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to={'hero'} smooth={true} duration={500} spy={true} exact={'true'}
                                          offset={-85}>
                                    Home
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to={'about'} smooth={true} duration={500} spy={true} exact={'true'}
                                          offset={-85}>
                                    About
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to={'products'} smooth={true} duration={500} spy={true} exact={'true'}
                                          offset={-85}>
                                    Products
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to={'discover'} smooth={true} duration={500} spy={true} exact={'true'}
                                          offset={-85}>
                                    Discover
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to={'contact'} smooth={true} duration={500} spy={true} exact={'true'}
                                          offset={-85}>
                                    Contact
                                </NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
