import React from 'react'
import {
    FooterContainer,
    FooterLink,
    FooterLinkItems,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkTitle,
    FooterWrapper,
    SocialMedia,
    SocialMediaIcon
} from './footer-style'
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa"

function Footer() {
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                            <FooterLink to={'/about'}>How it works</FooterLink>
                            <FooterLink to={'/about'}>Testimonials</FooterLink>
                            <FooterLink to={'/about'}>Careers</FooterLink>
                            <FooterLink to={'/about'}>Terms of Service</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Mozambique</FooterLinkTitle>
                            <FooterLink to={'/about'}>Edifficio Millenium Park</FooterLink>
                            <FooterLink to={'/about'}>AvenidaVladimir</FooterLink>
                            <FooterLink to={'/about'}>Lenine,174,Maputo 1100</FooterLink>
                            <FooterLink to={'/about'}>+258 852 945 794</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Malawi</FooterLinkTitle>
                            <FooterLink to={'/about'}>CC 217 Mudi Limbe</FooterLink>
                            <FooterLink to={'/about'}>P.O. Box 30199</FooterLink>
                            <FooterLink to={'/about'}>Chichiri, BT3</FooterLink>
                            <FooterLink to={'/about'}>+265 881 332 845</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Kenya</FooterLinkTitle>
                            <FooterLink to={'/about'}>Ngong Road Nairobi</FooterLink>
                            <FooterLink to={'/about'}>Kenya</FooterLink>
                            <FooterLink to={'/about'}> P.O.Box 1734, Nairobi</FooterLink>
                            <FooterLink to={'/about'}>+254 728 678 618</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaIcon href={'//www.facebook.com'} target={'_blank'} aria-label={'Facebook'}
                                     rel="noreferrer">
                        <FaFacebook/>
                    </SocialMediaIcon>
                    <SocialMediaIcon href={'//www.instagram.com'} target={'_blank'} aria-label={'Instagram'}
                                     rel="noreferrer">
                        <FaInstagram/>
                    </SocialMediaIcon>
                    <SocialMediaIcon href={'//www.twitter.com'} target={'_blank'} aria-label={'Twitter'}
                                     rel="noreferrer">
                        <FaTwitter/>
                    </SocialMediaIcon>
                    <SocialMediaIcon href={'//www.linkedin.com'} target={'_blank'} aria-label={'LinkedIn'}
                                     rel="noreferrer">
                        <FaLinkedin/>
                    </SocialMediaIcon>
                </SocialMedia>
                <p style={{
                    color: '#dcdcdc',
                    marginTop: '2rem',
                    fontWeight: 'lighter',
                    fontSize: '.8rem',
                    textAlign: 'center'
                }}>
                    Copyright © {new Date().getFullYear()} <b>Africa Development Connect</b>. All Rights Reserved
                </p>
                <p style={{marginTop: '1rem'}}>
                    <a href="https://www.pexels.com/video/running-a-light-of-digital-information-3129977/"
                       target={'_blank'} rel={'noreferrer'} style={{textDecoration: 'none', color: 'black'}}>
                        Video by Pressmaster from Pexels
                    </a>
                </p>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer
