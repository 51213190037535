import React, {useState} from 'react'
import Sidebar from "../sidebar/Sidebar"
import Navbar from "../navbar/Navbar"
import HeroSection from "../hero/HeroSection"
import InfoSection from "../info-section/InfoSection"
import {homeObj1, homeObj2, homeObj3, homeObj4, homeObj5, homeObj6, homeObj7} from "../info-section/Data"
import Services from "../services/Services"
import Contact from "./contact"
import Footer from "../footer/Footer"
import Partners from "../partners/Partners"
import WhoAreWe from "./WhoAreWe"
import Discover from "./Discover"
import Numbers from "./Numbers"

function Home() {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => setIsOpen(!isOpen)

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection/>
            <WhoAreWe/>
            <InfoSection {...homeObj1}/>
            <InfoSection {...homeObj2}/>
            <InfoSection {...homeObj3}/>
            <InfoSection {...homeObj4}/>
            <InfoSection {...homeObj5}/>
            <InfoSection {...homeObj6}/>
            <InfoSection {...homeObj7}/>
            <Discover/>
            <Numbers/>
            <Services/>
            <Partners/>
            <Contact/>
            <Footer/>
        </>
    )
}

export default Home
