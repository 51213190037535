import React from 'react'
import {
    BodyData, BodyInfo, BodyInfoContent, BodyInfoTitle, DiscoverBody, DiscoverContainer, DiscoverTitle,
    DiscoverWrapper, ItemImage
} from "./discover-styles"
import {discoverData} from "./DiscoverData"

function Discover() {
    return (
        <DiscoverContainer id={'discover'}>
            <DiscoverWrapper>
                <DiscoverTitle>Why Pick ADC?</DiscoverTitle>
                <DiscoverBody>
                    {discoverData.map(data => (
                        <BodyData key={data.id}>
                            <ItemImage src={data.img} alt={data.title}/>
                            <BodyInfo>
                                <BodyInfoTitle>{data.title}</BodyInfoTitle>
                                <BodyInfoContent>{data.description}</BodyInfoContent>
                            </BodyInfo>
                        </BodyData>
                    ))}
                </DiscoverBody>
            </DiscoverWrapper>
        </DiscoverContainer>
    )
}

export default Discover
