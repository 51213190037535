import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
  background: ${({scrollNav}) => (scrollNav ? '#000' : 'transparent')};
  height: 85px;
  margin-top: -85px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: .8s all ease;
  }
`

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 85px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1300px;
`

export const NavLogo = styled(LinkR)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Logo = styled.img`
  width: 250px;
`

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 938px) {
    display: block!important;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  
  @media screen and (max-width: 938px){
    display: none!important;
  }
`

export const NavItem = styled.li`
    height: 85px;
`

export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 98%;
  cursor: pointer;
  //font-size: 1.05rem;
  
  &.active{
    border-bottom: 5px solid #F69426;
  }
`
