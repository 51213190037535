import React from 'react'
import {
    CommentButton, ContactComment, ContactContainer, ContactContent, ContactHeader, ContactInput
} from "./contact-styles"

function Contact() {
    return (
        <ContactContainer id={'contact'}>
            <ContactContent>
                <ContactHeader>Leave a comment</ContactHeader>
                <ContactInput placeholder={'Enter Name'}/>
                <ContactInput placeholder={'Enter Email'}/>
                <ContactComment placeholder={'Your Comment'}/>
                <CommentButton>Send</CommentButton>
            </ContactContent>
        </ContactContainer>
    )
}

export default Contact
