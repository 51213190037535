import styled from "styled-components"

export const DiscoverContainer = styled.div`
  margin: 1rem auto;
`

export const DiscoverWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  margin: auto;
  padding: 1rem;
`

export const DiscoverTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 50px;
  margin-top: 1rem;
`

export const DiscoverBody = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  grid-gap: 1rem;
  
  @media screen and (max-width: 768px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 568px){
    grid-template-columns: 1fr;
  }
`

export const BodyData = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: .4fr 1fr;
  grid-gap: .5rem;
`

export const ItemImage = styled.img`
  width: 100px;
  height: 100px;
`

export const BodyInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const BodyInfoTitle = styled.h3`
  margin-bottom: .5rem;
`

export const BodyInfoContent = styled.p`
  font-size: 1rem;
`
